import { Resize } from '@cloudinary/url-gen/actions';
import { useFetcher } from 'react-router';
import { FieldDescription, FieldError, FieldLabel, Icon } from '@venncity/block';
import { Spin } from '@venncity/venn-ds';
import clsx from 'clsx';
import { isNil, noop } from 'lodash-es';
import React, { useState, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { cloudinary } from '~/lib/cloudinary';

enum ImageTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  SVG = 'image/svg+xml',
  GIF = 'image/gif',
}

interface ImageUploadProps {
  name: string;
  defaultValue?: string;
  accept?: `${ImageTypes}`[];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  errorMessage?: string | string[];
  label?: string;
  description?: string;
  className?: string;
}

const MAX_FILE_SIZE = 5_000_000; // bytes
const MAX_SIZE_ERROR_MESSAGE =
  'The file you tried to upload is too large. Please select a file up to 5MB.';

export function ImageUpload({
  name,
  defaultValue,
  accept = [ImageTypes.JPEG, ImageTypes.PNG, ImageTypes.GIF, ImageTypes.SVG],
  errorMessage,
  label,
  description,
  className,
  onChange = noop,
}: ImageUploadProps) {
  const [imageUrl, setImageUrl] = useState('');
  const [imageResourceId, setImageResourceId] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(!!defaultValue);
  const [sizeLimitErrorMessage, setSizeLimitErrorMessage] = React.useState('');
  const fetcher = useFetcher<{ image: string }>();
  const fileButtonRef = useRef<HTMLInputElement>(null);
  const isUploading =
    fetcher.formMethod === 'POST' &&
    (fetcher.state === 'submitting' || fetcher.state === 'loading');

  useEffect(() => {
    if (fetcher.data) {
      setImageResourceId(fetcher.data.image);
    }
  }, [fetcher.data]);

  React.useEffect(() => {
    if (defaultValue) {
      setImageUrl(
        cloudinary.image(defaultValue).format('webp').resize(Resize.scale().width(600)).toURL(),
      );
    }
  }, [defaultValue]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(e);

    setSizeLimitErrorMessage('');

    const file = e.target?.files?.[0] as File;

    if (file.size > MAX_FILE_SIZE) {
      return setSizeLimitErrorMessage(MAX_SIZE_ERROR_MESSAGE);
    }

    setImageUrl(URL.createObjectURL(file));

    const formData = new FormData();

    formData.append('img', file);

    fetcher.submit(formData, {
      method: 'post',
      encType: 'multipart/form-data',
      action: '/resources/cloudinary/image-upload',
    });
  }

  return (
    <div>
      {label && <FieldLabel>{label}</FieldLabel>}
      <div className={twMerge('flex h-52 w-full justify-center', className)}>
        <input
          accept={accept.join(',')}
          className="sr-only"
          id={name}
          key={imageResourceId}
          onChange={handleChange}
          ref={fileButtonRef}
          type="file"
        />
        <div className="text-grey-900 ring-grey-300 overflow bg-grey-400 hover:ring-grey-700 relative h-auto w-full rounded-xl ring-1 transition-shadow">
          {imageUrl && (
            <img
              alt="upload"
              className="object-no-repeat relative flex aspect-auto h-full max-h-80 w-full cursor-pointer flex-row-reverse rounded-xl object-cover object-center"
              onLoad={() => setIsLoading(false)}
              src={imageUrl}
            />
          )}
          <button
            className={clsx(
              'group absolute inset-0 flex cursor-pointer flex-col items-center justify-center rounded-xl p-4 transition-all hover:bg-black/30 focus:outline-none',
              (isUploading || isLoading) && 'bg-black/30',
            )}
            onClick={() => fileButtonRef?.current?.click()}
            type="button">
            {isUploading || isLoading ? (
              <Spin />
            ) : (
              <Icon
                className={clsx(
                  'h-12 w-12 text-white transition-opacity',
                  imageUrl ? 'opacity-0 group-hover:opacity-100' : 'opacity-100',
                )}
                name="camera"
              />
            )}
          </button>
          <input name={name} type="hidden" value={isNil(imageResourceId) ? '' : imageResourceId} />
        </div>
      </div>
      <div className="flex flex-col">
        {(errorMessage || sizeLimitErrorMessage) && (
          <FieldError>{errorMessage || sizeLimitErrorMessage}</FieldError>
        )}
        <FieldDescription>
          {description || 'Maximum file size: 5MB (jpg, png, gif)'}
        </FieldDescription>
      </div>
    </div>
  );
}
